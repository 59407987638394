export const getEncoreWynnMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-wynn-route.jpg'),
            mapLine: 'EncoreWynnPath',
            venueBg:  require('@/assets/maps/wynn.png'),
            venue: 'Encore & Wynn',
            distance: '3.5',
            singleLineVenue: false,
            threeLineVenue: false
        }
    ]
}
